@import "~/styles/mixins.scss";

.loginForm {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: rgba(var(--primary-700), 1);
  padding: var(--spacing-5);
  border-radius: 16px;

  label {
    @include text-large-body-bold;
    width: 100%;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: var(--spacing-4);
  }
  small {
    @include text-large-body-regular;
    display: block;
    color: white;
  }
  &__error {
    font-size: 12px;
    line-height: 18px;
    color: rgba(var(--error-border), 1);
    margin-top: -18px;
  }
  input {
    @include text-large-body-bold;
    position: relative;
    width: 100%;
    height: 80px;
    background-color: rgba(var(--primary-900), 1);
    border: 2px solid rgba(var(--primary-900), 1);
    color: rgba(var(--text-secondary), 1);
    padding: 28px 34px;
    border-radius: 8px;
    &:not(:focus):invalid,
    &[data-error="true"] {
      background-color: rgba(var(--error-bg), 1);
      border-color: rgba(var(--error-border), 1);
    }
  }
  button {
    align-self: center;
    margin-top: var(--spacing-5);
  }
}

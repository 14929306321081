@import "~/styles/mixins.scss";

.popupModalWrapper {
  position: fixed;
  z-index: 5;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--primary-900), 0.6);
}

.popupModal {
  max-width: 800px;
  padding: 80px 120px;
  border-radius: 16px;
  background-color: rgba(var(--primary-700), 1);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    @include text-h2;
  }
  h3 {
    @include text-h3;
    margin-top: var(--spacing-3);
  }
  p {
    @include text-h4;
    white-space: pre-wrap;
    &:first-of-type {
      margin-top: var(--spacing-3);
    }
  }
  &__buttons {
    margin-top: var(--spacing-6);
    display: flex;
    flex-direction: row;
    gap: var(--spacing-3);
  }
}

.popupModalSmall {
  max-width: 600px;
  padding: 40px;
  border-radius: 16px;
  background-color: rgba(var(--primary-700), 1);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    @include text-h4;
  }
  h3 {
    @include text-large-body-bold;
    margin-top: var(--spacing-3);
  }
  p {
    @include text-body-regular;
    white-space: pre-wrap;
    margin-bottom: var(--spacing-2);
    &:first-of-type {
      margin-top: var(--spacing-3);
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__buttons {
    margin-top: var(--spacing-4);
    display: flex;
    flex-direction: row;
    gap: var(--spacing-3);
  }
}

@import "~/styles/mixins.scss";

.indexPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-5);
  background-color: rgba(var(--primary-bg), 1);
  &__helpLink {
    color: white;
  }
}
